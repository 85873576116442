export const BlockchainsConfig = [
    {
        name: 'Ethereum',
        chainId: 1,
        rpc: 'https://silent-lingering-silence.quiknode.pro/070e558d9af4d777c940ce38c408742006c8d403/',
        explorer: 'https://etherscan.io/address/%s'
    },
    {
        name: 'BNB',
        chainId: 56,
        rpc: 'https://bsc-dataseed.binance.org',
        explorer: 'https://bscscan.com/address/%s'
    },
    {
        name: 'Polygon',
        chainId: 137,
        rpc: 'https://ultra-wispy-wave.matic.quiknode.pro/17084ed5fb8536f171035b36d26638181651b8d1/',
        explorer: 'https://polygonscan.com/address/%s'
    },
    {
        name: 'Arbitrum',
        chainId: 42161,
        rpc: 'https://arbitrum-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        explorer: 'https://arbiscan.io/address/%s'
    },
    {
        name: 'Avalanche',
        chainId: 43114,
        rpc: 'https://api.avax.network/ext/bc/C/rpc',
        explorer: 'https://snowscan.xyz/address/%s'
    },
    {
        name: 'Linea',
        chainId: 59144,
        rpc: 'https://rpc.linea.build/',
        explorer: 'https://lineascan.build/address/%s'
    },
    {
        name: 'Base',
        chainId: 8453,
        rpc: 'https://mainnet.base.org',
        explorer: 'https://basescan.org/address/%s'
    },
    {
        name: 'Optimism',
        chainId: 10,
        rpc: 'https://mainnet.optimism.io',
        explorer: 'https://optimistic.etherscan.io/address/%s'
    },
    {
        name: 'Neon',
        chainId: 245022934,
        rpc: 'https://neon-mainnet.everstake.one',
        explorer: 'https://neonscan.org/address/%s'
    },
    {
        name: 'Gnosis',
        chainId: 100,
        rpc: 'https://rpc.eu-central-2.gateway.fm/v4/gnosis/non-archival/mainnet',
        explorer: 'https://gnosisscan.io/address/%s'
    },
    {
        name: 'LL',
        chainId: 1890,
        rpc: 'https://replicator.phoenix.lightlink.io/rpc/v1',
        explorer: 'https://phoenix.lightlink.ioaddress/%s'
    },
    {
        name: 'Metis',
        chainId: 1890,
        rpc: 'https://metis-mainnet.public.blastapi.io',
        explorer: 'https://explorer.metis.io/address/%s'
    },
    {
        name: 'Bitrock',
        chainId: 7171,
        rpc: 'https://connect.bit-rock.io',
        explorer: 'https://explorer.bit-rock.io/address/%s'
    },
] as const