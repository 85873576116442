import './App.css';
import Table from './Table';
import { useState } from 'react';
import { BlockchainsContext, getBlockchains, TBlockchainsContext } from './Blockchains';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Summary } from './Summary';
import { CheckRouters } from './CheckRouters';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Summary/>,
  },
  {
    path: "/check-routers",
    element: <CheckRouters/>
  }
]);

function App() {
  const [blockchains] = useState<TBlockchainsContext>(getBlockchains())

  return (
  <BlockchainsContext.Provider value={blockchains}>
    <RouterProvider router={router} />
  </BlockchainsContext.Provider>
  );
}

export default App;
