import React, { useEffect, useState } from 'react';
import './Table.css';
import { TBlockchain } from './Blockchains';
import { BlockchainContract, contracts } from './Contracts';
import Web3 from 'web3';

// Simple FNV-1a hash function for strings
function hashString(str: string): number {
    let hash = 0x811c9dc5;
    for (let i = 0; i < str.length; i++) {
        hash ^= str.charCodeAt(i);
        hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
    }
    return hash >>> 0; // Ensure the result is a positive number
}

// Function to convert a number to a HEX color
function numberToHexColor(num: number): string {
    const r = (num & 0xFF0000) >> 16;
    const g = (num & 0x00FF00) >> 8;
    const b = num & 0x0000FF;
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

// Function to generate a reproducible HEX color based on a string input
function generateHexColor(input: string): string {
    const hash = hashString(input);
    return numberToHexColor(hash);
}

export function ContractVersion({ blockchain, contract }: { blockchain: TBlockchain, contract: BlockchainContract }) {
    const [contractAddress, setContractAddress] = useState<string | undefined>()
    const [contractData, setContractData] = useState<{ version: string; implAddress: string; bytecode: string; bytecodeHash: string } | false | undefined>();

    useEffect(() => {
        if (typeof contract.address === 'function')
            contract.address(blockchain.chainId, contracts, blockchain.web3)
                .then(v =>  {
                    setContractAddress(v)
                })
        else setContractAddress(contract.address)
    }, [contract.address])

    if (!contractAddress)
        return <>👀</>;

    if (undefined === contractData) {
        const contractX = new blockchain.web3.eth.Contract(contract.abi, contractAddress);
        contractX.methods.version().call()
            .then(async (version: any) => {
                const storageValue = await blockchain.web3.eth.getStorageAt(contractAddress, '0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc')
                const implAddress: string = blockchain.web3.eth.abi.decodeParameter('address', storageValue) as string;
                const bytecode = await blockchain.web3.eth.getCode(implAddress);
                setContractData({ version: version.toString(), implAddress, bytecode, bytecodeHash: Web3.utils.keccak256(bytecode).slice(0, 4*2) })
            })
            .catch(e => {
                console.error(e)
                setContractData(false)
            })
        return <td>👀</td>;
    }

    if (false === contractData) return (<td>❌</td>)

    return (<td style={{
        backgroundColor: generateHexColor(contractData.version)
    }}>
        <div>v{contractData.version}</div>
        <div style={{fontSize: '0.7em'}}>
            impl hash: <a href={blockchain.explorer.replace('%s', contractData.implAddress)} style={{
                color: generateHexColor( contractData.bytecodeHash),
            }} target='_blank'>
                {contractData.bytecodeHash}
            </a>
        </div>
    </td>)
}