import { Address } from "./Address";
import { useGetBlockchains } from "./Blockchains";
import { contracts } from "./Contracts";
import { ContractVersion } from "./ContractVersion";
import { SupportedRouter } from "./SupportedRouterFlag";

export function CheckRoutersTable(props: { addresses: string[] }) {
    const blockchains = useGetBlockchains();

    return (
      <table className='table table-bordered table-hover border-primary table-responsive-sm contracts-table'>
          <thead>
              <tr>
                  <th>Network</th>
                  { props.addresses.map(addr => <th><Address>{addr}</Address></th>) }
              </tr>
          </thead>
          <tbody>
              {blockchains.map(blockchain =>
                  <tr>
                      <td>{blockchain.name}</td>
                      { props.addresses.map(address =>
                            <SupportedRouter address={address} blockchain={blockchain} contract={contracts.find(f => f.name === 'DeBridgeRouter')!}/>
                      ) }
                  </tr>
              )}
          </tbody>
      </table>
    )
}