import { useState, useEffect } from "react";
import Web3 from "web3";
import { TBlockchain } from "./Blockchains";
import { BlockchainContract, contracts } from "./Contracts";


export function SupportedRouter({ address, blockchain, contract }: { address: string, blockchain: TBlockchain, contract: BlockchainContract }) {
    const [contractAddress, setContractAddress] = useState<string>()
    const [routerEnabled, setRouterEnabled] = useState<boolean>();

    useEffect(() => {
        if (typeof contract.address === 'function')
            contract.address(blockchain.chainId, contracts, blockchain.web3)
                .then(v =>  {
                    setContractAddress(v)
                })
        else setContractAddress(contract.address)
    }, [contract.address])

    if (!contractAddress)
        return <>👀</>;

    if (undefined === routerEnabled) {
        const contractX = new blockchain.web3.eth.Contract(contract.abi, contractAddress);
        contractX.methods.supportedRouters(address).call()
            .then(async (isEnabled: any) => {
                setRouterEnabled(isEnabled)
            })
            .catch(e => {
                console.error(e)
                setRouterEnabled(false)
            })
        return <td>👀</td>;
    }

    return (<td>
        <div>{routerEnabled ? '✅' : '❌'}</div>
    </td>)
}