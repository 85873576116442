import React from 'react';
import './Table.css';
import { useGetBlockchains } from './Blockchains';
import { contracts } from './Contracts';
import { ContractVersion } from './ContractVersion';

function Table() {
  const blockchains = useGetBlockchains();

  return (
    <table className='table table-bordered table-hover border-primary table-responsive-sm contracts-table'>
        <thead>
            <tr>
                <th>Network</th>
                { contracts.map(contract => <th>{contract.name}</th>) }
            </tr>
        </thead>
        <tbody>
            {blockchains.map(blockchain =>
                <tr>
                    <td>{blockchain.name}</td>
                    { contracts.map(contract =>
                            <ContractVersion blockchain={blockchain} contract={contract}/>
                    ) }
                </tr>
            )}
        </tbody>
    </table>
  );
}

export default Table;
